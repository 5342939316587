@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    scroll-behavior: smooth;
}

body {
    background-color: #FFF5EC;
}

.wrapper{
    overflow: hidden;
    max-width: 2200px;
    margin: 0 auto;
}



.btn_orange{
    @apply text-white bg-def_orange focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2
}

/* .btn_orange:hover{
    background: rgba(239,116,73,1);
} */
