.nav_cont{
    @apply border-gray-200 w-full py-3
}
/* 
.nav_wrapper{
    @apply 
} */

.nav_logo{
    @apply flex items-center space-x-3 rtl:space-x-reverse
}

.nav_btn{
    @apply inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200
}

/* .menu_cont{
    @apply 
} */

/* .list_cont{
    @apply 
} */

.menu_elem{
    @apply block text-lg duration-500 sm:hover:scale-110 hover:text-orange-400 py-2 md:px-3 text-gray-900 rounded
}